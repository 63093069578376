import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons"
import { Carousel, Image } from "antd"
import React from "react"
import Page from "../components/page"
import { Paragraphs } from "../components/paragraph"
import useWindowSize from "../utils/useWindowSize"

export default function Home({ labels, ...props }) {
  const [width, height] = useWindowSize()

  const paragraphs = [
    {
      id: labels.saceParagraphId,
      title: labels.saceDigitalServicesUppercased,
      text: [labels.saceText1, labels.saceText2],
      textDivided: true,
      orientation: "horizontal",
      version: "primary",
      textOrientation: "right"
    },
    {
      id: labels.eArchiveParagraphId,
      title: labels.eArchiveDigitalLockerUppercased,
      text: [
        labels.eArchiveText1,
        labels.eArchiveText2,
        <div>
          <div>{labels.eArchiveText3}</div>
          <div>{labels.eArchiveText4}</div>
        </div>
      ],
      textDivided: true,
      orientation: "horizontal",
      version: "secondary",
      textOrientation: "left"
    },
    {
      id: labels.eTravelBookParagraphId,
      title: labels.eTravelBookDigitalAssistantUppercased,
      text: [
        <div>
          <div>{labels.eTravelBookDigitalAssistantText1}</div>
          <div>{labels.eTravelBookDigitalAssistantText2}</div>
          <div>
            {labels.eTravelBookDigitalAssistantText31}{" "}
            <a
              href="http://www.sace.hr"
              target="_blank"
              rel="noopener noreferrer"
            >
              {labels.saceUppercased}
            </a>{" "}
            {labels.eTravelBookDigitalAssistantText32}
          </div>
          <div>{labels.eTravelBookDigitalAssistantText4}</div>
          <div>{labels.eTravelBookDigitalAssistantText5}</div>
          <div>{labels.eTravelBookDigitalAssistantText6}</div>
          <div>{labels.eTravelBookDigitalAssistantText7}</div>
          <div>{labels.eTravelBookDigitalAssistantText8}</div>
        </div>
      ],
      textDivided: true,
      orientation: "horizontal",
      version: "primary",
      textOrientation: "right"
    },
    {
      id: labels.cdcParagraphId,
      title: labels.centralizedDigitalCommunicationUppercased,
      text: labels.centralizedDigitalCommunicationText,
      orientation: "horizontal",
      version: "secondary",
      textOrientation: "left"
    },
    {
      id: labels.bpaParagraphId,
      title: labels.businessProcessAutomatizationUppercased,
      text: [
        labels.businessProcessAutomatizationText1,
        labels.businessProcessAutomatizationText2
      ],
      textDivided: true,
      orientation: "horizontal",
      version: "primary",
      textOrientation: "right"
    },
    {
      id: labels.remoteWorkParagraphId,
      title: labels.remoteWorkUppercased,
      text: labels.remoteWorkText,
      orientation: "horizontal",
      version: "secondary",
      textOrientation: "left"
    },
    {
      id: labels.ictParagraphId,
      title: labels.ictSystemSolutionDesignUppercased,
      text: [
        labels.ictSystemSolutionDesignText1,
        labels.ictSystemSolutionDesignText2,
        labels.ictSystemSolutionDesignText3
      ],
      textDivided: true,
      orientation: "horizontal",
      version: "primary",
      textOrientation: "right"
    }
  ]

  let images = undefined

  if (height > width) {
    images = [
      { src: labels.homeImage1Vertical, alt: "laptop" },
      { src: labels.homeImage2Vertical, alt: "book" },
      { src: labels.homeImage3Vertical, alt: "sace_hr" },
      { src: labels.homeImage4Vertical, alt: "pyramid_hr" },
      { src: labels.homeImage5Vertical, alt: "pamflet_hr" },
      { src: labels.homeImage6Vertical, alt: "poem" }
    ]
  } else {
    images = [
      { src: labels.homeImage1, alt: "laptop" },
      { src: labels.homeImage2, alt: "book" },
      { src: labels.homeImage3, alt: "sace_hr" },
      { src: labels.homeImage4, alt: "pyramid_hr" },
      { src: labels.homeImage5, alt: "pamflet_hr" },
      { src: labels.homeImage6, alt: "poem" }
    ]
  }

  return (
    <Page {...props} labels={labels}>
      <Carousel
        arrows
        nextArrow={<CaretRightOutlined />}
        prevArrow={<CaretLeftOutlined />}
      >
        {images
          ? images.map(img => (
              <Image src={img.src} preview={false} alt={img.alt} />
            ))
          : null}
      </Carousel>

      <Paragraphs paragraphs={paragraphs} className="normal-links" />
    </Page>
  )
}
